<template>
  <base-section
    id="features"
    class="accent"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-city-variant-outline',
          title: 'Dublin Ireland',
          text: 'Based out of the heart of Dublin City.',
        },
        {
          icon: 'mdi-pencil-ruler',
          title: 'Designers',
          text: 'Bringing your vision & business to life.',
        },
        {
          icon: 'mdi-keyboard',
          title: 'Developers',
          text: 'Full stack development web & mobile apps.',
        },
        {
          icon: 'mdi-auto-fix',
          title: 'noting we cant do',
          text: 'Our Y-Squared guarentee.',
        },
      ],
    }),
  }
</script>
